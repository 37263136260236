import Vue from 'vue'
import './polyfill'
import './$system'
import './ada'
import './variable'
import { Loading } from '@shein/sui-mobile'
import './is_bot'
import { markPoint } from 'public/src/services/mark/index.js'
import eventBus from './eventBus/core'

/* eslint-disable */
Vue.component('s-loading', Loading)
/* eslint-enable */
window.appEventCenter = new Vue()

window.eventBus = new eventBus()

markPoint({ eventName: 'mountingJsLoaded', measureTo: 'commonJsLoaded', })
if (window.navtrack) {
  window.navtrack.navtrack_mountingJs = Date.now()
}

