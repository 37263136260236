window._gb_passwordSafetyExp = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/
window._gb_privacyEurCountry = [13, 21, 33, 56, 54, 57, 58, 68, 73, 74, 82, 85, 98, 104, 106, 116, 122, 123, 131, 150, 172, 173, 177, 192, 193, 198, 206, 225]
window.appRouteExtData = { direction: 0 }// -1=浏览器回退，0=正常页面内跳转，1=浏览器前进


// 追踪加车来源
window.gbAddBagTraceFrom = function gbAddBagTraceFrom(type, payload) {
  var tracefrom = null
  var addBagFrom = ''
  try {
    tracefrom = JSON.parse(sessionStorage.getItem('saAddBagTraceFrom')) || {}
    if (type == 'setProductFrom') {
      tracefrom[payload.goods_id] = payload.from || 'other'
    } else if (type == 'getProductFrom') {
      addBagFrom = tracefrom[payload.goods_id] || 'other'
    }
    sessionStorage.setItem('saAddBagTraceFrom', JSON.stringify(tracefrom))
    return addBagFrom
  } catch (error) {
    console.log(error)
  }
}

window.GB_SHEIN_load_script = function GB_SHEIN_load_script(url, callback) {
  if (Array.from(document.getElementsByTagName('script')).find(i => i.src.indexOf(url) > -1)) return callback()
  var script = document.createElement('script')
  script.type = 'text/javascript'

  if (script.readyState) {
    // IE
    script.onreadystatechange = function () {
      if (script.readyState == 'loaded' || script.readyState == 'complete') {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    // Others
    script.onload = function () {
      callback()
    }
  }
  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}

window.GB_SHEIN_counting_star = function GB_SHEIN_counting_star(num, size) {
  var star_html = ''
  for (let i = 0; i < parseInt(num); i++) {
    star_html += '<i class="icon-star_' + size + '_4 iconfont"></i>'
  }
  var decimal = Number((num + '').split('.')[1]) || 0
  if (decimal > 0) {
    if (decimal <= 33) {
      star_html += '<i class="icon-star_' + size + '_1 iconfont"></i>'
    } else if (decimal <= 66) {
      star_html += '<i class="icon-star_' + size + '_2 iconfont"></i>'
    } else if (decimal <= 99) {
      star_html += '<i class="icon-star_' + size + '_3 iconfont"></i>'
    }
  }
  for (let i = 0; i < 5 - parseInt(num) - (decimal > 0 ? 1 : 0); i++) {
    star_html += '<i class="icon-star_' + size + '_0 iconfont"></i>'
  }
  return star_html
}

window.GB_SHEIN_counting_star_suiicon = function GB_SHEIN_counting_star_suiicon(num, size, type = '') {
  var star_html = ''
  for (let i = 0; i < parseInt(num); i++) {
    star_html += `<i class="sui_icon_star_5_${size}px${type ? '_' + type : ''} suiiconfont"></i>`
  }
  var decimal = Number(parseFloat(num).toFixed(2).split('.')[1]) || 0
  if (decimal > 0) {
    if (decimal <= 25) {
      star_html += `<i class="sui_icon_star_2_${size}px${type ? '_' + type : ''} suiiconfont"></i>`
    } else if (decimal <= 50) {
      star_html += `<i class="sui_icon_star_3_${size}px${type ? '_' + type : ''} suiiconfont"></i>`
    } else if (decimal <= 99) {
      star_html += `<i class="sui_icon_star_4_${size}px${type ? '_' + type : ''} suiiconfont"></i>`
    }
  }
  for (let i = 0; i < 5 - parseInt(num) - (decimal > 0 ? 1 : 0); i++) {
    star_html += `<i class="sui_icon_star_1_${size}px${type ? '_' + type : ''} suiiconfont"></i>`
  }
  return star_html
}

window.replaceHrefSpeCha = function replaceHrefSpeCha(href, { transformHttps = true } = {}) {
  if (typeof href !== 'string') return href
  let hrefOrigin = href && href.match(/^http(s)?:\/\/(.*?)\//g)
  if (hrefOrigin) {
    hrefOrigin = hrefOrigin[0]
    if (hrefOrigin && !hrefOrigin.match('\.shein\.') && !hrefOrigin.match('\.romwe\.')) {
      return href
    }
  }

  // 搜索排除
  if (/pdsearch/.test(href)) return href

  var pathBegin = href.indexOf('://') > 0 ? href.indexOf('://') + 2 : 0
  var seachBegin = href.indexOf('?', pathBegin) > 0 ? href.indexOf('?', pathBegin) : href.length
  var protocol = href.substring(0, pathBegin)
  var pathName = href.substring(pathBegin, seachBegin)
  var search = href.substring(seachBegin, href.length)
  pathName = pathName.replace(/[><#@$\s'"%+&]|[\u4e00-\u9fa5]/g, '-')

  if (protocol && transformHttps) {
    protocol = protocol.replace('http:/', 'https:/')
  }

  href = (protocol + pathName + search).replace(/-{2,}/g, '-')
  return href
}
