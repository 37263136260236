import Vue from 'vue'
import adaModal from './adamodal'
import moveClick from './moveclick'

/**
 * tabIndex 优先级, 1 > 2 > 3 > ... > (0 || FormEle || a标签)
 *
 * 前言:
 * 采用 正tabIndex 目的是为了解决
 * a. 修改/优化 ADA体验时, 无需进行修改 DOM结构 这种不现实操作
 * b. 常规 Ele 与 0 地位相同而干扰聚焦次序
 *
 * W3C不建议使用 正tabIndex, 原因是觉得会扰乱用户的理解
 * 但是使用恰当时并不会有这种可能, 毕竟优先级是产品定的
 * 按规则填写v-ada参数即可, 各个板块应预留充足空间
 */

/**
 * ADA类
 * 1. 通过自定义字段标识元素, 提供同 tabIndex 嵌套/乱序/多同级聚合 的聚焦次序控制
 * 2. ↔ 可聚焦同级不同元素 ( v-for场景 )
 * 3. ↕ 可切入|退出嵌套结构 ( 树状场景 )
 * 4. level间控制分离
 * 5. 条件渲染元素序列控制集成
 */
class ADA {
  constructor() {
    document.addEventListener('focusin', () => this.syncFocus())
    document.addEventListener('keydown', e => this.switchFocus(e))
    const setFocusOnFirstTabIndexElement = () => document.querySelector(`[tabindex="1"]`)?.focus()
    if (document.readyState === 'complete') {
      setTimeout(setFocusOnFirstTabIndexElement, 0)
    } else {
      window.addEventListener('load', setFocusOnFirstTabIndexElement)
    }
  }
  syncFocus() {
    const { FocusEle, adaLevel } = ADA.getFocusAdaEle()
    if (!FocusEle || FocusEle.tabIndex === +adaLevel) return

    document
      .querySelectorAll(`[data-ada-level="${adaLevel}"]`)
      .forEach(node => node.tabIndex = -1)
    FocusEle.tabIndex = adaLevel
    FocusEle.focus()
  }
  switchFocus(e) {
    const { FocusEle, adaLevel, adaPos, adaAdaption } = ADA.getFocusAdaEle()
    if (!FocusEle) return

    const { direction, ranking } = this.analysisKeyCode(e.code)
    const targetEle = this.getTarget({ adaLevel, adaPos, adaAdaption, direction, ranking })
    if (!targetEle) return

    targetEle.tabIndex = adaLevel
    targetEle.focus()
    FocusEle.tabIndex = -1
    ranking && e.preventDefault()
  }
  analysisKeyCode(code) {
    let direction, ranking
    switch (code) {
      case 'ArrowLeft':
        direction = -1; break
      case 'ArrowRight':
        direction = 1; break
      case 'ArrowUp':
        ranking = -1; break
      case 'ArrowDown':
        ranking = 1; break
    }
    return { direction, ranking }
  }
  getTarget({ adaLevel, adaPos, adaAdaption, direction, ranking }) {
    const arr = adaPos.split('-')
    if (direction) {
      let originPos = +arr.pop()
      const adaAdaption = ADA.getTargetDom({ adaLevel, adaPos: arr.join('-') })?.dataset.adaAdaption
      do {
        originPos += direction
        const target = ADA.getTargetDom({ adaLevel, adaPos: [...arr, originPos].join('-') })
        if (target) return target
      } while (originPos > 0 && originPos < +adaAdaption)
    }
    if (ranking) {
      if (ranking === 1) {
        let originPos = 0
        do {
          const target = ADA.getTargetDom({ adaLevel, adaPos: [...arr, originPos].join('-') })
          if (target) return target
        } while (++originPos <= +adaAdaption)
      } else {
        arr.pop()
        return ADA.getTargetDom({ adaLevel, adaPos: arr.join('-') })
      }
    }
  }

  static getFocusAdaEle() {
    if (typeof document === 'undefined') return {}

    const FocusEle = document.activeElement
    const { adaLevel, adaPos, adaAdaption } = FocusEle.dataset
    if (!adaLevel || !adaPos) return {}

    return { FocusEle, adaLevel, adaPos, adaAdaption }
  }
  static getTargetDom({ adaLevel, adaPos }) {
    if (!adaPos) return

    const selector = `[data-ada-level="${adaLevel}"][data-ada-pos="${adaPos}"]`
    return document.querySelector(selector)
  }
  static focusAdaEle({ adaLevel, adaPos }) {
    if (typeof document === 'undefined') return
    ADA.getTargetDom({ adaLevel, adaPos })?.focus()
  }
  static setAdaData(el, { value: { level, pos, adaption } = {} }) {
    if (typeof document === 'undefined') return
    pos = Array.isArray(pos) && pos.join('-') || String(pos)

    const { FocusEle, adaLevel } = ADA.getFocusAdaEle()
    el.tabIndex = el === FocusEle || level !== +adaLevel && pos === '0' ? level : -1

    el.dataset.adaLevel = level
    el.dataset.adaPos = pos
    adaption && (el.dataset.adaAdaption = adaption)
  }
  static focusParentAdaEle() {
    let { adaLevel, adaPos } = ADA.getFocusAdaEle()
    if(adaPos){
      const pos = adaPos.lastIndexOf(adaPos.match(/-\d+$/)?.[0])
      if(pos != -1) ADA.focusAdaEle({ adaLevel, adaPos: adaPos.slice(0, pos) })
    }
  }
}

/**
 * v-ada 指令: 添加 ADA类 所依赖的自定义属性 ( Dom结构有序场景, 无需使用 v-ada)
 * v-ada="{ level: (tabIndex to set), pos: (position(s) of the element(s)), adaption, subAdaption }"
 *
 * 参数对象接收level, pos
 * @param {Number} level 指定tabIndex
 * ( 为了方便统一管理/拓展level, 必须采用业务容器配置化维护组件level值 )
 * @param {Number|Array} pos 指定元素位置, 嵌套结构应按父级到子级次序依次传入元素下标
 * *( start at 0, 保证index连续, 针对条件渲染内容, 由使用方独立维护index, 参考列表item.vue)
 * @param {Number} adaption 下一层级最大聚焦个数(用于条件渲染场景)[由于0索引原因, 可选元素集必须被包含于一个固定根]
 * ( 下一层级有条件渲染元素时, 此元素讲尝试在范围内匹配首个找到的元素, 子层级互切范围亦取决于此值 )
 *
 * e.g.
 * 单层
 * v-ada="{ level: 2, pos: 0 }"
 * v-ada="{ level: 2, pos: [1] }"
 * 嵌套
 * v-ada="{ level: 4, pos: [0,5] }"
 * 条件
 * v-ada="{ level: 2, pos: 0, adaption: 2 }"
 */
Vue.directive('ada', {
  inserted: ADA.setAdaData,
  componentUpdated: ADA.setAdaData
})

Vue.directive('enterkey', {
  // 指令的定义
  bind: function (el, binding, vnode) {
    el.addEventListener('keydown', function(event){
      if ((event?.keyCode === 13 || event?.keyCode === 108) && vnode?.data?.on?.click) {
        vnode.data.on.click(event)
      }
    })
  }
})
Vue.directive('ada-modal', adaModal)
/*
 * 只需父级使用v-ada-radio-group, radio项只要添加role="radio"
 * 上下左右键切换选中，当focus的radio没有选中，可用空格与回车选中
 * demo可参考orderList.vue
 */
Vue.directive('ada-radio-group', moveClick)


typeof window !== 'undefined' && new ADA()

export default ADA
