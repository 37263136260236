import { isUnDef } from '@shein/common-function'

class EventBus {
  constructor() {
    this.eventBus = {}
  }

  #validateEventName(eventName) {
    if (typeof eventName !== 'string') {
      console.error('eventName must be a string')
      return true
    }
  }

  #validateEventCb(eventCb) {
    if (typeof eventCb !== 'function') {
      console.error('eventCb must be a function')
      return true
    }
  }

  #validateEmptyEvent(eventName) {
    if (!this.eventBus[eventName]?.length) return true
  }

  #setEvent({ eventName, eventCb, once = false }) {
    if (this.#validateEventName(eventName) || this.#validateEventCb(eventCb)) return

    if (!this.eventBus[eventName]) {
      this.eventBus[eventName] = []
    }

    this.eventBus[eventName].push({
      handler: eventCb,
      once
    })
  }

  on(eventName, eventCb) {
    this.#setEvent({ eventName, eventCb })

    return this
  }

  once(eventName, eventCb) {
    this.#setEvent({ eventName, eventCb, once: true })

    return this
  }

  emit(eventName, ...args) {
    if (this.#validateEventName(eventName) || this.#validateEmptyEvent(eventName)) return this

    const delEventIndex = []
    this.eventBus[eventName].forEach(({ handler, once }, index) => {
      handler(...args)

      if (once) {
        delEventIndex.push(index)
      }
    })

    if (!delEventIndex.length) return this

    this.eventBus[eventName] = this.eventBus[eventName].filter((item, idx) => !delEventIndex.includes(idx))

    return this
  }

  off(eventName, eventCb) {
    if (
      this.#validateEventName(eventName) || 
      this.#validateEmptyEvent(eventName)
    ) return this

    // 不传eventCb时，删除eventName下所有事件
    if (isUnDef(eventCb)) {
      Reflect.defineProperty(this.eventBus, eventName)
      return
    }

    this.eventBus[eventName] = this.eventBus[eventName].filter(({ handler }) => handler !== eventCb)

    return this
  }
}

export default EventBus
