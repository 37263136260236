/* globals $System */
window.$System = { Math: {}, DS: {}, Type: {} }
$System.Type.TypeUtil = function () {
  throw new Error('$System.Type.TypeUtil is a static class.')
}
$System.Type.TypeUtil.isString = function (a) {
  return typeof a === 'string' || (typeof a === 'object' && a instanceof String)
}
$System.Type.TypeUtil.isDate = function (a) {
  return typeof a === 'object' && a instanceof Date
}
$System.Type.TypeUtil.isRegExp = function (a) {
  return typeof a === 'object' && a instanceof RegExp
}
$System.Type.TypeUtil.isArray = function (a) {
  return typeof a === 'object' && a instanceof Array
}
$System.Type.TypeUtil.isNumber = function (a) {
  return !isNaN(a)
}
$System.Type.TypeUtil.isBoolean = function (a) {
  return typeof a === 'boolean' || (typeof a === 'object' && a instanceof Boolean)
}
$System.Type.TypeUtil.isFunction = function (a) {
  return typeof a === 'function' || (typeof a === 'object' && a instanceof Function)
}
$System.Type.TypeUtil.isInteger = function (a) {
  return $System.Type.TypeUtil.isNumber(a) && Math.round(a) == a
}
$System.Type.TypeUtil.isFloat = function (a) {
  if ($System.Type.TypeUtil.isString(a)) {
    return false
  }
  return $System.Type.TypeUtil.isNumber(a) && Math.round(a) != a
}
$System.Type.TypeUtil.isError = function (a) {
  return typeof a === 'object' && a instanceof Error
}
$System.DS.HashTable = function () {
  this.o = {}
  for (const key in this.o) {
    delete this.o[key]
  }
  this.size = 0
}
$System.DS.HashTable.prototype.getSize = function () {
  return this.size
}
$System.DS.HashTable.prototype.set = $System.DS.HashTable.prototype.add = function (a, b) {
  if (!(a in this.o)) {
    this.size++
  }
  this.o[a] = { v: b }
}
$System.DS.HashTable.prototype.get = function (a) {
  if (a in this.o) {
    return this.o[a]['v']
  }
  return null
}
$System.DS.HashTable.prototype.hasThisKey = function (a) {
  return a in this.o
}
$System.DS.HashTable.prototype.getKeyListByValue = function (b) {
  var a = []
  for (const key in this.o) {
    if (this.o[key]['v'] == b) {
      a.push(key)
    }
  }
  return a
}
$System.DS.HashTable.prototype.remove = function (a) {
  if (a in this.o) {
    this.size--
    delete this.o[a]['v']
    delete this.o[a]
  }
}
$System.DS.HashTable.prototype.removeAll = function () {
  for (const key in this.o) {
    delete this.o[key]['v']
    delete this.o[key]
  }
  this.size = 0
}
$System.DS.HashTable.prototype.getO = function () {
  return this.o
}
$System.DS.HashTable.prototype.join = function (e, d) {
  var b = []
  for (const key in this.o) {
    b.push(key + e + this.get(key))
  }
  return b.join(d)
}
$System.DS.HashTable.dispose = function (a) {
  a.dispose()
  a = null
}
$System.DS.HashTable.prototype.dispose = function () {
  this.removeAll()
}
$System.Math.IntUtil = function () {
  throw new Error('$System.Math.IntUtil is a static class.')
}
$System.Math.IntUtil.dec2Bin = function (a) {
  return a.toString(2)
}
$System.Math.IntUtil.hexNumArray = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F']
$System.Math.IntUtil.dec2Hex = function (a) {
  return a.toString(16).toUpperCase()
}
$System.Math.IntUtil.numHexArray = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  A: '10',
  B: '11',
  C: '12',
  D: '13',
  E: '14',
  F: '15'
}
$System.Math.IntUtil.hex2Dec = function (a) {
  return parseInt(a, 16)
}
$System.Math.IntUtil.bin2Dec = function (a) {
  return parseInt(a, 2)
}
$System.Math.IntUtil.binHexArray = {
  '0000': '0',
  '0001': '1',
  '0010': '2',
  '0011': '3',
  '0100': '4',
  '0101': '5',
  '0110': '6',
  '0111': '7',
  1000: '8',
  1001: '9',
  1010: 'A',
  1011: 'B',
  1100: 'C',
  1101: 'D',
  1110: 'E',
  1111: 'F'
}
$System.Math.IntUtil.bin2Hex = function (b) {
  var g = 0
  while (b.charAt(g) == '0') {
    g++
  }
  if (g > 0) {
    b = b.substring(g, b.length)
  }
  var d
  var e = []
  var f = $System.Math.IntUtil.binHexArray
  var a = b.length
  for (d = a; d > 3; d = d - 4) {
    e.push(f[b.substring(d - 4, d)])
  }
  e.push(f[$System.Math.IntUtil.fillZero(b.substring(d - 4, d), 4)])
  return e.reverse().join('')
}
$System.Math.IntUtil.hexBinArray = {
  0: '0000',
  1: '0001',
  2: '0010',
  3: '0011',
  4: '0100',
  5: '0101',
  6: '0110',
  7: '0111',
  8: '1000',
  9: '1001',
  A: '1010',
  B: '1011',
  C: '1100',
  D: '1101',
  E: '1110',
  F: '1111'
}
$System.Math.IntUtil.hex2Bin = function (d) {
  var h = 0
  while (d.charAt(h) == '0') {
    h++
  }
  if (h > 0) {
    d = d.substring(h, d.length)
  }
  var e = d.split('')
  var a = e.length
  var f = []
  var g = $System.Math.IntUtil.hexBinArray
  for (var b = 0; b < a; b++) {
    f.push(g[e[b]])
  }
  return f.join('')
}
$System.Math.IntUtil.fillZero = function (d, f, e) {
  if (d == '' && !e) {
    return ''
  }
  var c = f - d.length
  var b = ''
  for (var a = 0; a < c; a++) {
    b = '0' + b
  }
  return b + d
}
$System.Math.IntUtil.__GUIDPool = new $System.DS.HashTable()
$System.Math.IntUtil.__GUIDCheckAndAdd = function (a) {
  if (!$System.Math.IntUtil.__GUIDPool.hasThisKey(a)) {
    $System.Math.IntUtil.__GUIDPool.set(a, true)
    return true
  } else {
    return false
  }
}
$System.Math.IntUtil.genGUIDV4 = function () {
  var g = $System.Math.IntUtil.dec2Hex(Math.floor(Math.random() * ($System.Math.IntUtil.hex2Dec('FFFFFFFF') + 1)))
  var f = $System.Math.IntUtil.dec2Hex(Math.floor(Math.random() * 65536))
  var i = $System.Math.IntUtil.fillZero($System.Math.IntUtil.dec2Bin(Math.floor(Math.random() * 65536)), 16)
  var e = $System.Math.IntUtil.bin2Hex(i.substr(0, 12) + '0100')
  var h = $System.Math.IntUtil.fillZero($System.Math.IntUtil.dec2Bin(Math.floor(Math.random() * 256)))
  var d = $System.Math.IntUtil.bin2Hex(h.substr(0, 5) + '01' + h.substr(7, 1))
  var b = $System.Math.IntUtil.dec2Hex(Math.floor(Math.random() * 256))
  var a = $System.Math.IntUtil.dec2Hex(Math.floor(Math.random() * ($System.Math.IntUtil.hex2Dec('FFFFFFFFFFFF') + 1)))
  g = $System.Math.IntUtil.fillZero(g, 8)
  f = $System.Math.IntUtil.fillZero(f, 4)
  e = $System.Math.IntUtil.fillZero(e, 4)
  d = $System.Math.IntUtil.fillZero(d, 2)
  b = $System.Math.IntUtil.fillZero(b, 2)
  a = $System.Math.IntUtil.fillZero(a, 12)
  var j = g + '_' + f + '_' + e + '_' + d + b + '_' + a
  if ($System.Math.IntUtil.__GUIDCheckAndAdd(j)) {
    return j
  } else {
    return $System.Math.IntUtil.genGUIDV4()
  }
}
$System.Math.IntUtil.fillRnd = function (b, e, d) {
  if (b == '' && !d) {
    return ''
  }
  const c = e - b.length
  var a = ''
  if (c > 0) {
    a = $System.Math.IntUtil.dec2Hex(Math.floor(Math.random() * ($System.Math.IntUtil.hex2Dec('FFFFFFFFFFFF') + 1)))
    a = a.substring(0, c)
  }
  return a + b
}
$System.Math.IntUtil.genGUID = function () {
  var g = $System.Math.IntUtil.dec2Hex(Math.floor(Math.random() * ($System.Math.IntUtil.hex2Dec('FFFFFFFF') + 1)))
  var f = $System.Math.IntUtil.dec2Hex(Math.floor(Math.random() * 65536))
  var i = $System.Math.IntUtil.fillZero($System.Math.IntUtil.dec2Bin(Math.floor(Math.random() * 65536)), 16)
  var e = $System.Math.IntUtil.bin2Hex(i.substr(0, 12) + '0100')
  var h = $System.Math.IntUtil.fillZero($System.Math.IntUtil.dec2Bin(Math.floor(Math.random() * 256)))
  var d = $System.Math.IntUtil.bin2Hex(h.substr(0, 5) + '01' + h.substr(7, 1))
  var b = $System.Math.IntUtil.dec2Hex(Math.floor(Math.random() * 256))
  var a = $System.Math.IntUtil.dec2Hex(Math.floor(Math.random() * 1000000000000))
  g = $System.Math.IntUtil.fillZero(g, 8)
  f = $System.Math.IntUtil.fillZero(f, 4)
  e = $System.Math.IntUtil.fillZero(e, 4)
  d = $System.Math.IntUtil.fillZero(d, 2)
  b = $System.Math.IntUtil.fillZero(b, 2)
  a = $System.Math.IntUtil.fillRnd(a, 12)
  var j = a + '_' + f + '_' + e + '_' + d + b + '_' + g
  if ($System.Math.IntUtil.__GUIDCheckAndAdd(j)) {
    return j
  } else {
    return $System.Math.IntUtil.genGUID()
  }
}
